// @flow
import { graphql } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import Footer from '../components/Footer';
import GetStarted from '../components/GetStarted';
import Header from '../components/header';
import LearnMore from '../components/LearnMore';
import Page from '../components/Page';
import { MEDIUM_BREAKPOINT, query } from '../style';

import type { ImageSharp, FullProvider } from '../types.js';

type Props = {
  data: {
    contentfulProvider: FullProvider,
  },
  pageContext: {
    accomplishments: ?string,
    bio: ?string,
    research: ?string,
  },
};

export default class Index extends React.Component<Props> {
  render() {
    const { contentfulProvider: provider } = this.props.data;
    const { accomplishments, bio, research } = this.props.pageContext;
    return (
      <Page title={provider.name}>
        <Header
          thin
          title="Meet our team"
          subtitle="Dealing with a spine problem can be anxiety inducing. Our commitment is to help you through your care and recovery, treating you as a person, not just a patient."
          cta="Make an appointment"
        />
        <DocHeader>
          <MaxWidth>
            <DocImage
              src={provider.headshot.resolutions.base64}
              srcSet={provider.headshot.resolutions.srcSet}
            />
            <Vert>
              <DocName>{provider.name}</DocName>
              <DocType>{provider.type}</DocType>
            </Vert>
          </MaxWidth>
        </DocHeader>
        <DocBody>
          <Text
            dangerouslySetInnerHTML={{
              __html: provider.bio.childMarkdownRemark.html,
            }}
          />
        </DocBody>
        <LearnMore
          body="There is plenty that makes us different, but if you have any questions
          don't hesitate to call."
          team={false}
        />
        <GetStarted />
        <Footer />
      </Page>
    );
  }
}

const MaxWidth = styled.div`
  && {
    ${query(MEDIUM_BREAKPOINT)`
    max-width: 700px;
    margin: auto;
    padding: 20px;
    display: flex;
    flex-direction: row;
  `};
  }
`;

const BulletList = styled.ul`
  && {
  }
`;

const Bullet = styled.li`
  && {
  }
`;

const SectionTitle = styled.h5`
  && {
    margin-bottom: 1.08rem;
  }
`;

const DocBody = styled.div`
  && {
    padding: 20px;

    ${query(MEDIUM_BREAKPOINT)`
  max-width: 700px;
  margin: auto;
`};
  }
`;

const Text = styled.p`
  && {
  }
`;

const DocHeader = styled.section`
  && {
    background: #eef2f7;
    padding-bottom: 20px;
  }
`;

const Vert = styled.div`
  && {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
`;

const DocImage = styled.img`
  && {
    width: 100%;
    object-fit: contain;

    ${query(MEDIUM_BREAKPOINT)`
    width: 250px;
    height: 250px;
  `};
  }
`;

const DocName = styled.h1`
  && {
    padding: 0px 20px;
    margin-top: 20px;
    margin-bottom: 0;
  }
`;

const DocType = styled.h4`
  && {
    padding: 0px 20px;
    margin-top: 0;
    opacity: 0.7;
  }
`;

export const pageQuery = graphql`
  query oneDocDup($name: String!) {
    contentfulProvider(name: { eq: $name }) {
      name
      headshot {
        resolutions {
          base64
          src
          srcSet
        }
      }
      type
      bio {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
